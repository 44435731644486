export const getPriceType = ({
    isAuctionEnabled,
    amountType,
    textType,
}: {
    isAuctionEnabled: boolean | null | undefined;
    amountType: string | null | undefined;
    textType: string | null | undefined;
}): string => {
    let priceType = 'list price';

    if (textType === 'SOLD') {
        priceType = 'item sold';
    } else if (textType === 'UNAVAILABLE') {
        priceType = 'item unavailable';
    } else if (amountType === 'WEEKLY_SALE') {
        priceType = 'saturday sale';
    } else if (amountType === 'NET') {
        priceType = 'net price';
    } else if (isAuctionEnabled && amountType === 'RETAIL') {
        priceType = 'auction price';
    }

    return priceType;
};
