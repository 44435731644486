import { graphql, readInlineData } from 'react-relay';
import SV from 'server-vars';
import { trackEcommerce, EcomProduct, trackingConstants, eventNameConstants } from 'dibs-tracking';
import { hasInternalTrafficCookie } from 'dibs-cookie-jar';
import { LOCALE_US } from 'dibs-intl/exports/locales';
import { pageTypeConstants as pageTypes } from '../../../constants/pageTypeConstants';
import { ECOMMERCE_ATTRIBUTES } from '../../../constants/attributesConstants';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { getPriceType } from '../shared/displayPriceTracking';
import { getEngagedItems } from 'dibs-buyer-layout/exports/engagedItems';

import {
    getIsFreeShipping,
    getLowestEligibleShippingCost,
    UserRegions,
} from 'dibs-search-product-tile/exports/shippingCostHelpers';

import { ecommerceTrackingProductData_item$key } from './__generated__/ecommerceTrackingProductData_item.graphql';
import { ecommerceTrackingUserProductData_item$key } from './__generated__/ecommerceTrackingUserProductData_item.graphql';
import { ecommerceTracking_item$key } from './__generated__/ecommerceTracking_item.graphql';
import { ecommerceTracking_itemSearch$key } from './__generated__/ecommerceTracking_itemSearch.graphql';

const LIST_TRACKING_SEARCH = 'search results listing';
const LIST_TRACKING_BROWSE = 'browse page listing';
const LIST_TRACKING_ATTRIBUTE = 'attribute listing';
const LIST_TRACKING_STOREFRONT = 'storefront listing';
const LIST_TRACKING_BUY_PAGE = 'buy page';
const LIST_TRACKING_SEMANTIC = 'semantic result listing';
const LIST_TRACKING_RELATED_ITEMS = 'null results - related items';

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

export const NUMBER_OF_PRODUCT_IMPRESSIONS = 3;

const itemSearchFragment = graphql`
    fragment ecommerceTracking_itemSearch on ItemSearchQueryConnection @inline {
        pageType
        searchTerm
        sponsored {
            metadata {
                itemId
                impressionTrackerLink
                clickTrackerLink
            }
        }
    }
`;

const itemFragment = graphql`
    fragment ecommerceTracking_item on Item @inline {
        serviceId
        ...ecommerceTrackingProductData_item
        ...ecommerceTrackingUserProductData_item
    }
`;

const trackSponsoredItemsEvent = (link: string): void => {
    if (!hasInternalTrafficCookie(document.cookie)) {
        fetch(link, {
            method: 'GET',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
        });
    }
};

export const getListType = (
    pageType: string,
    searchCorrectionTerm: string,
    isRelatedSearchItems?: boolean
): string => {
    const { SEARCH, BROWSE, DEALER } = pageTypes;

    if (searchCorrectionTerm && pageType !== SEARCH) {
        return LIST_TRACKING_SEMANTIC;
    } else if (isRelatedSearchItems) {
        return LIST_TRACKING_RELATED_ITEMS;
    } else if (pageType === SEARCH) {
        return LIST_TRACKING_SEARCH;
    } else if (pageType === BROWSE) {
        return LIST_TRACKING_BROWSE;
    } else if (ECOMMERCE_ATTRIBUTES.includes(pageType)) {
        return LIST_TRACKING_ATTRIBUTE;
    } else if (pageType === DEALER) {
        return LIST_TRACKING_STOREFRONT;
    } else if (pageType === PAGE_TYPE.BUY) {
        return LIST_TRACKING_BUY_PAGE;
    } else {
        return `${pageType} listing`;
    }
};

const getProductData = ({
    index,
    isSponsored,
    item: itemRef,
    pageType,
    searchTerm,
    searchCorrectionTerm = '',
    /**
     * Ideally we would avoid using the global version of SV, keeping this for legacy code.
     */
    locale = SV.get('locale'),
    isRelatedSearchItems,
}: {
    isSponsored: boolean;
    index: number;
    item: ecommerceTrackingProductData_item$key | null | undefined;
    pageType: string;
    searchTerm: string | null;
    searchCorrectionTerm?: string;
    locale?: string;
    isRelatedSearchItems?: boolean;
}): EcomProduct => {
    const item = readInlineData(
        graphql`
            fragment ecommerceTrackingProductData_item on Item @inline {
                serviceId
                title
                seller {
                    serviceId
                }
                displayPriceTracking: displayPrice(
                    page: $pageDisplayEnum
                    isTrade: $isTrade
                    userCountryCode: $userCountryCode
                    priceBookName: $priceBookName
                ) {
                    convertedAmountList {
                        amount
                        currency
                    }
                    amountType
                    textType
                }
                categoryCode
                browseUrl
                contemporaryTrackingString
                isAuctionEnabled
                isNewListing
                personalizationType
            }
        `,
        itemRef
    );
    const {
        serviceId,
        title,
        seller,
        displayPriceTracking,
        categoryCode,
        browseUrl,
        contemporaryTrackingString,
        isAuctionEnabled,
        isNewListing,
        personalizationType,
    } = item || {};

    const primaryDisplayPriceTracking = displayPriceTracking?.[0];
    const { convertedAmountList, amountType, textType } = primaryDisplayPriceTracking || {};

    const priceType = getPriceType({ isAuctionEnabled, amountType, textType });

    const usdPrice = (convertedAmountList || []).find(
        convertedAmount => convertedAmount?.currency === 'USD'
    );

    const isSearch = pageType === pageTypes.SEARCH;
    const isBuy = pageType === pageTypes.BUY;

    return {
        id: serviceId,
        name: locale === LOCALE_US ? title : 'n/a',
        position: index + 1, // 1-indexed
        brand: seller?.serviceId,
        category: browseUrl,
        categoryCode,
        dimension76: priceType,
        dimension83: contemporaryTrackingString,
        price: usdPrice?.amount || 0,
        list: getListType(pageType, searchCorrectionTerm, isRelatedSearchItems),
        dimension141: isSponsored,
        dimension142: isNewListing || false,
        dimension143: personalizationType ?? null,
        ...((searchTerm && isSearch) || searchCorrectionTerm
            ? {
                  dimension98: searchCorrectionTerm || searchTerm,
                  searchCategoryClassification: isSearch && searchCorrectionTerm ? 'Y' : 'N',
              }
            : {}),
        ...(!(isSearch || isBuy) && serviceId
            ? {
                  isBoost: !isSponsored && getEngagedItems().includes(serviceId),
              }
            : {}),
    };
};

function getUserProductData({
    item: itemRef,
    userRegions,
}: {
    item: ecommerceTrackingUserProductData_item$key | null | undefined;
    userRegions: UserRegions;
}): {
    dimension106: string;
    dimension120: number | undefined;
} {
    const item = readInlineData(
        graphql`
            fragment ecommerceTrackingUserProductData_item on Item @inline {
                ...shippingCostHelpers_item
            }
        `,
        itemRef
    );
    return {
        dimension106: getIsFreeShipping({ item, userRegions }) ? 'Y' : 'N',
        dimension120: getLowestEligibleShippingCost({ item, userRegions }),
    };
}

export const trackEcommerceProductImpressions = ({
    itemSearch: itemSearchRef,
    items: itemsRefArray,
    itemsData,
    pageType,
    searchCorrectionTerm,
    userRegions,
    isRelatedSearchItems,
}: {
    itemSearch: ecommerceTracking_itemSearch$key;
    items: ReadonlyArray<ecommerceTracking_item$key> | null | undefined;
    itemsData: { index: number; isSponsored?: boolean }[];
    pageType?: string;
    searchCorrectionTerm?: string;
    userRegions: UserRegions;
    isRelatedSearchItems?: boolean;
}): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const items = (itemsRefArray || []).map(itemRef => readInlineData(itemFragment, itemRef));

    const { searchTerm } = itemSearch;

    const itemProductData = itemsData.map(({ index, isSponsored }) => {
        const item = items?.[index] || null;
        const { serviceId } = item;
        const impressionTrackerLink =
            isSponsored &&
            itemSearch?.sponsored?.metadata?.find(data => serviceId && serviceId === data?.itemId)
                ?.impressionTrackerLink;
        if (impressionTrackerLink) {
            trackSponsoredItemsEvent(impressionTrackerLink);
        }

        return {
            ...getUserProductData({
                item,
                userRegions,
            }),
            ...getProductData({
                isSponsored: !!impressionTrackerLink,
                pageType: pageType || itemSearch.pageType || pageTypes.BROWSE,
                searchTerm,
                searchCorrectionTerm,
                item,
                index,
                isRelatedSearchItems,
            }),
        };
    });

    trackEcommerce({
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        products: itemProductData,
    });
};

export const trackEcommerceProductClick = ({
    itemSearch: itemSearchRef,
    items: itemsRef,
    index,
    isSponsored,
    pageType,
    searchCorrectionTerm = '',
    userRegions,
}: {
    itemSearch: ecommerceTracking_itemSearch$key;
    items: ReadonlyArray<ecommerceTracking_item$key> | null | undefined;
    index: number;
    isSponsored?: boolean;
    pageType?: string;
    searchCorrectionTerm?: string;
    userRegions: UserRegions;
}): void => {
    const itemSearch = readInlineData(itemSearchFragment, itemSearchRef);
    const item = readInlineData(itemFragment, itemsRef?.[index] || null);

    const { searchTerm } = itemSearch;

    const { serviceId } = item || {};
    const clickTrackerLink =
        isSponsored &&
        itemSearch?.sponsored?.metadata?.find(data => serviceId && serviceId === data?.itemId)
            ?.clickTrackerLink;

    if (clickTrackerLink) {
        trackSponsoredItemsEvent(clickTrackerLink);
    }

    trackEcommerce({
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        actionField: {
            list: getListType(
                pageType || itemSearch.pageType || pageTypes.BROWSE,
                searchCorrectionTerm
            ),
            itemId: item?.serviceId,
        },
        products: [
            {
                ...getProductData({
                    isSponsored: !!clickTrackerLink,
                    item,
                    pageType: pageType || itemSearch.pageType || pageTypes.BROWSE,
                    index,
                    searchTerm,
                    searchCorrectionTerm,
                }),
                ...getUserProductData({
                    item,
                    userRegions,
                }),
            },
        ],
    });
};
